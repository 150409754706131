import React, { Component } from 'react'
import { connect } from 'dva';
import { Modal, List, Icon, NavBar, InputItem, Button } from 'antd-mobile';
import router from 'umi/router';
import styles from './index.less';

@connect(({ vin }) => ({ vin }))
class Vin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vin: ''
    }
  }

  checkVin(vin) {

    if(vin.length > 0 && vin.length != 17){
      return false;
    }else{
      var vinVal = vin.toUpperCase();
      //document.getElementById("vin_"+k).value = vinVal;
      var charToNum = {'A':1,'B':2,'C':3,'D':4,'E':5,'F':6,'G':7,'H':8,'J':1,'K':2,'L':3,'M':4,'N':5,'P':7,'R':9,'S':2,'T':3,'U':4,'V':5,'W':6,'X':7,'Y':8,'Z':9};
      var obj = 0;
      var arr = new Array();
      for (var i = 0 ; i < vinVal.length; i++) {
        var temp = vinVal.charAt(i);

        if(charToNum[temp]){
          arr[i] = charToNum[temp];
        }else{
          arr[i] = Number(temp);
        }
        if(i==8){
          arr[i] = vinVal.charAt(i);
        }

      };	
      var a1 = 8;
      for (var i = 0; i < 7; i++) {
        obj += Number(arr[i]) * a1 ;
        a1--;
      };

      obj += Number(arr[7])*10;

      var a2 = 9;
      for (var i = 9; i < 17; i++) {
        obj += Number(arr[i]) * a2;
        a2--;
      };

      var result = Number(obj)%11; 
      if(parseInt(result) === 10){
        result = 'X';
      }
      return result == arr[8];
    }
  }

  submit() {
    let { vin } = this.state;
    vin = vin.trim();
    if (!this.checkVin(vin)) {
      alert('请输入正确的vin码');
      return;
    }
    
    const { dispatch } = this.props;
    dispatch({
      type: 'vin/setVin',
      payload: {
        vin
      }, 
      callback: (res) => {
        if (res.code == 200) {
          router.push('/personalInfo');
        } else {
          alert(res.message);
        }
      }
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({
      type: 'vin/getVin',
      payload: { },
      callback: (res) => {
        this.setState({vin: res.data})
      }
    })
  }

  render() {

    return (<div>
      <NavBar
        mode="light"
        icon={<Icon type="left" />}
        onLeftClick={() => router.push('/my')}
      >VIN码</NavBar>
      <InputItem value={this.state.vin} onChange={(v) => this.setState({vin: v})} className={styles.vincode} placeholder="输入VIN码" />
      <Button type="primary" className={styles.submit} onClick={this.submit.bind(this)}>保存</Button>
    </div>)
  }

}

export default Vin;