import React, { Component } from 'react';
import { connect } from 'dva';
import {  NavBar, Icon, Button, Flex, ActivityIndicator } from 'antd-mobile';
//import { LeftOutlined } from '@ant-design/icons';
import router from 'umi/router';
import styles from './index.less';
import '../../global-vars';

// http://localhost/monitor?pileNo=32010300000356

@connect(({monitor}) => ({monitor}))
class Monitor extends Component {

  state = {
    stopBtnClass: "stop-btn",
    stopBtnText: "停止充电",//"已结束充电",
    voltage: "0",
    current: "0",
    soc: "0",
    degree: "0",
    accumulatedTime: "0小时0分",
    timeRemaining: "0小时0分",
    paidMoney: "100",
    usedMoney: "0",
    sn: "",
    isScanning: false
  }

  pileNo = null;

  componentDidMount() {
    const { location: { query } } = this.props;
    if ( !global.vars.sn1 || global.vars.sn1.length < 2) {
      alert("未成功获取桩编号。请重进充电页面，并等待页面加载完成！");
      return;
    }
    this.pileNo = global.vars.sn1;//query.pileNo;
    this.setState({
      sn: this.pileNo
    });
    console.log(this.pileNo);
    this.getPrePayMoney();
    this.pollForMonitorData(this.pileNo);
    this.pollTimer = setInterval(
      () => this.pollForMonitorData(this.pileNo),
      60000
    );


    // if (isCharging) {
    //   if (!this.pollTimer) {
    //     this.pollTimer = setInterval(
    //       () => this.pollForMonitorData("32010300000356"),
    //       60000
    //     );
    //   }
    // } else {
    //   clearInterval(this.pollTimer)
    // }
  }

  componentWillUnmount() {
    if (this.pollTimer) {
      clearInterval(this.pollTimer);
    }
  }

  getPrePayMoney = () => {
    const { dispatch } = this.props;
    var sn = this.pileNo;
    dispatch({
      type: 'monitor/getPrePayMoney',
      payload: { sn },
      callback: (res) => {
        if (!res||isNaN(res)) {
          //alert("网络错误！"+res);
          this.setState({
            paidMoney: 0.0
          });
        } else {
          this.setState({
            paidMoney: res
          });
        }
      }
    })
  }

  pollForMonitorData = (pileNo) => {
    const { dispatch } = this.props;
    //var pileNo = "32010300000356";
    dispatch({
      type: 'monitor/getMonitorData',
      payload: { pileNo },
      callback: (res) => {
        if (!res||!res.message||res.message.length<2) {
          //clearInterval(this.pollTimer);
          return;
        }
        if (res.code == 200) {
          this.processMonitorDataStr(res.message);
        }
      }
    })
  }

  processMonitorDataStr = (str) => {
    if (str == "done") {
      // 已结束充电
      global.vars.isCharging = false;
      this.setState({
        stopBtnText: "已结束充电",
        stopBtnClass: "done-btn"
      });
      clearInterval(this.pollTimer);
      return;
    }
    var arr = str.split("#");
    var aTimeStr;
    var rTimeStr;
    var aTime = parseInt(arr[2]);
    var aHour = parseInt(aTime/60);
    var aMin = aTime%60;
    aTimeStr = aHour+"小时"+aMin+"分";
    var rTime = parseInt(arr[3]);
    var rHour = parseInt(rTime/60);
    var rMin = rTime%60;
    rTimeStr = rHour+"小时"+rMin+"分";
    this.setState({
      voltage: arr[0],
      current: arr[1],
      soc: arr[4],
      degree: arr[5],
      accumulatedTime: aTimeStr,
      timeRemaining: rTimeStr,
      usedMoney: arr[6]
    });
  }

  stopCharge = () => {
    if (this.state.stopBtnClass == "done-btn") {
      return;
    }
    if ( !this.pileNo || this.pileNo.length < 2) {
      this.setState({
        stopBtnText: "已结束充电",
        stopBtnClass: "done-btn"
      });
      global.vars.isCharging = false;
      return;
    }
    const { dispatch } = this.props;
    var sn = this.pileNo;
    this.setState({
      isScanning: true
    });
    dispatch({
      type: 'monitor/stopCharge',
      payload: { sn },
      callback: (res) => {
        this.setState({
          isScanning: false
        });
        if (res.code == 200) {
          this.setState({
            stopBtnText: "已结束充电",
            stopBtnClass: "done-btn"
          });
          global.vars.isCharging = false;
          clearInterval(this.pollTimer);
        } else if (res.code == 400) {
          alert(res.message);
        } else if (res.code == 500) {
          //alert(res.message);
        } else {
          alert("网络错误404");
        }
      }
    })
  }

  render() {
    return (
      <div>
        <div>
          <ActivityIndicator toast text="正在加载" animating={this.state.isScanning} />
        </div>
        <div className="monitorPage">
          <Flex direction="column">
            <Flex.Item className="col-item">
              <NavBar
                mode="light"
                icon={<Icon type="left" />}
                onLeftClick={() => router.replace('/charge')}
              >实时充电数据</NavBar>
            </Flex.Item>
            <Flex.Item className="col-item-1">
              <div>桩编号: {this.state.sn}</div>
            </Flex.Item>
            <Flex.Item className="col-item">
              <div className="gray-line"></div>
            </Flex.Item>
            <Flex.Item className="col-item">
              <Flex direction="row">
                <Flex.Item className="row-left-item">
                  <div>实时电压</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">
                  <div>{this.state.voltage}V</div>
                </Flex.Item>
                <Flex.Item className="row-left-item">
                  <div>电池电量</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">
                  <div><span>{this.state.soc}</span>%</div>
                </Flex.Item>
              </Flex>
            </Flex.Item>
            <Flex.Item className="col-item">
              <Flex direction="row">
                <Flex.Item className="row-left-item">  
                  <div>实时电流</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">  
                  <div><span>{this.state.current}</span>A</div>
                </Flex.Item>
                <Flex.Item className="row-left-item">
                  <div>充电度数</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">  
                  <div><span>{this.state.degree}</span>度</div>
                </Flex.Item>
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <div className="charging-now-gif"></div>
            </Flex.Item>          
            <Flex.Item className="col-item">
              <Flex direction="row">
                <Flex.Item className="row-left-item">
                  <div>累计充电时间</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">
                  <div>{this.state.accumulatedTime}</div>
                </Flex.Item>
                <Flex.Item className="row-left-item">
                  <div>已充值</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">
                  <div>{this.state.paidMoney}元</div>
                </Flex.Item>
              </Flex>
            </Flex.Item>
            <Flex.Item className="col-item">
              <Flex direction="row">
                <Flex.Item className="row-left-item">
                  <div>剩余时间</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">
                  <div>{this.state.timeRemaining}</div>
                </Flex.Item>
                <Flex.Item className="row-left-item">
                  <div>已用金额</div>
                </Flex.Item>
                <Flex.Item className="row-right-item">
                  <div>{this.state.usedMoney}元</div>
                </Flex.Item>
              </Flex>
            </Flex.Item>
            <Flex.Item className="col-item">
              <div className="gray-line"></div>
            </Flex.Item>
            <Flex.Item className="col-item-2">
              <div>
                如果在充电桩屏幕扫码后未支付直接弹出此页面，说明此充电桩有正在处理中的异常订单，等待约1小时处理完后，即可扫码充电。由于网络延迟，充电数据金额以充电桩屏幕显示为准。电池电量只有直流桩才有值。
              </div>
            </Flex.Item>
            <Flex.Item>
              <Button className={this.state.stopBtnClass} onClick={this.stopCharge.bind(this)}>
                {this.state.stopBtnText}
              </Button>
            </Flex.Item>
          </Flex>
        </div>
      </div>
    );
  }
}

export default Monitor;
