import React, { Component } from 'react'
import { connect } from 'dva';
import { Modal, List, Icon, NavBar, InputItem, Button } from 'antd-mobile';
import router from 'umi/router';
import styles from './index.less';

import LicenseKeyboard from 'vehicle-plate-keyboard';
import 'vehicle-plate-keyboard/dist/main.css';

@connect(({ car_no }) => ({ car_no }))
class CarNo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showKeyboard: false,
      value: ''
    }
  }

  checkCarNo(vehicleNumber) {
  
    var reg =/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF][A-HJ-NP-Z0-9][0-9]{4}))$/;
  
    return reg.test(vehicleNumber);
  }

  submit() {
    let { value } = this.state;
    value = value.trim();
    if (!this.checkCarNo(value)) {
      alert('请输入正确的车牌号');
      return;
    }
    
    const { dispatch } = this.props;
    dispatch({
      type: 'car_no/setCarNo',
      payload: {
        carNo: value
      }, 
      callback: (res) => {
        if (res.code == 200) {
          router.push('/personalInfo');
        } else {
          alert(res.message);
        }
      }
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({
      type: 'car_no/getCarNo',
      payload: { },
      callback: (res) => {
        this.setState({value: res.data || ''})
      }
    })
  }

  render() {

    return (<div>
      <NavBar
        mode="light"
        icon={<Icon type="left" />}
        onLeftClick={() => router.push('/my')}
      >车牌号</NavBar>
      <InputItem value={this.state.value} onFocus={() => this.setState({showKeyboard: true})} className={styles.car_no} placeholder="输入车牌号" />

      <LicenseKeyboard
        visible={this.state.showKeyboard}
        onChange={value => this.setState({ value })}
        value={this.state.value}
        done={() => this.setState({ showKeyboard: false })}     
        ></LicenseKeyboard>
      <Button type="primary" className={styles.submit} onClick={this.submit.bind(this)}>保存</Button>
    </div>)
  }

}

export default CarNo;