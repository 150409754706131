import React, { Component } from 'react'
import { connect } from 'dva';
import { Modal, List, Icon, NavBar } from 'antd-mobile';
import router from 'umi/router';
import styles from './index.less';
import fa from '../../assets/css/font-awesome.css';
import '../../global-vars';

@connect(({ support }) => ({ support }))
class Support extends Component {

  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      address: ''
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if ( !global.vars.sn1 || global.vars.sn1.length < 2) {
      alert("未成功获取桩编号。请重进充电页面，并等待页面加载完成！");
      return;
    }
    var sn = global.vars.sn1;
    dispatch({
      type: 'support/getSupport',
      payload: { sn },
      callback: (res) => {
        this.setState({
          phone: res.data.phone,
          address: res.data.address
        });
      }
    })
  }

  render() {
    return (<div>
      <NavBar
        mode="light"
        icon={<Icon type="left" />}
        onLeftClick={() => router.push('/my')}
      >客服信息</NavBar>
      <div className={"info1"}>客服电话: {this.state.phone}</div>
      <div className={"info1"}>充电桩物业: {this.state.address}</div>
    </div>)
  }

}

export default Support;
