import { setVin, getVin } from 'services/vin';
import router from 'umi/router';
export default {
  namespace: 'vin',
  state: {
  },
  effects: {
    *setVin({ payload, callback }, { call, put }) {
      const response = yield call(setVin, payload);

      callback(response);
    },
    *getVin({ payload, callback }, { call, put }) {
      const response = yield call(getVin, payload);

      callback(response)
    }
  },
  reducers: {
  }
};
