import proxyRequest from 'utils/request';
import { baseUrl } from '@/utils/baseServer';

export async function stopCharge(params) {
  return proxyRequest.get(baseUrl + '/charge/testStopCharge', params);
}

export async function getMonitorData(params) {
  return proxyRequest.get(baseUrl + '/charge/getMonitorData', params);
}

export async function getPrePayMoney(params) {
  return proxyRequest.get(baseUrl + '/charge/getPrePayMoney', params);
}

