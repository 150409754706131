import proxyRequest from 'utils/request';
import { baseUrl } from '@/utils/baseServer';

// export async function auth(params) {
//   return proxyRequest.get(baseUrl + '/portal/js/auth', params);
// }

export async function toBind(params) {
  return proxyRequest.get(baseUrl + '/portal/to/bind', params);
}

// export async function sendCode(params) {
//   return proxyRequest.post(baseUrl + '/portal/send/code', params);
// }

// export async function bindPhone(params) {
//   return proxyRequest.post(baseUrl + '/portal/bind', params);
// }
