import { auth, toBind, sendCode, bindPhone } from '../../services/bind';
import router from 'umi/router';

export default {
  namespace: 'bind',
  state: {
  },
  effects: {
    *js({ payload, callback }, { call, put }) {
      const response = yield call(auth, payload);
      console.log('response', response)
      callback(response)
    },
    *toBind({ payload, callback }, { call, put }) {
      const response = yield call(toBind, payload);
      console.log('response', response)
      callback(response)
    },
    *sendCode({ payload, callback }, { call, put }) {
      const response = yield call(sendCode, payload);
      callback(response);
    },
    *bindPhone({ payload, callback }, { call, put }) {
      const response = yield call(bindPhone, payload);
      if (response.code == 200) {
        router.push('/');
      }
    }
  },
  reducers: {
    setData(state, { payload }) {
      return {
        ...state,
        list: payload,
      }
    }
  },
  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, search }) => {
        if (pathname == '/home'||pathname == '/') {
          dispatch({
            type: 'reg',
          });
        }
      });
    },
  },
};
