import proxyRequest from 'utils/request';
import { baseUrl } from '@/utils/baseServer';

export async function getCarNo(params) {
  return proxyRequest.get(`${baseUrl}/portal/car_no`, params);
}

export async function setCarNo(params) {
  return proxyRequest.post(`${baseUrl}/portal/car_no`, params);
}
