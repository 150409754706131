import { stopCharge, getMonitorData, getPrePayMoney } from '../../services/monitor';
import router from 'umi/router';
export default {
  namespace: 'monitor',
  state: {
  },
  effects: {
    *stopCharge({ payload, callback }, { call }) {
      const response = yield call(stopCharge, payload);
      console.log('response', response)
      callback(response)
    },
    *getPrePayMoney({ payload, callback }, { call }) {
      const response = yield call(getPrePayMoney, payload);
      console.log('response', response)
      callback(response)
    },
    *getMonitorData({ payload, callback }, { call }) {
      const response = yield call(getMonitorData, payload);
      console.log('response', response)
      callback(response)
    }
  },
  reducers: {
    scanHandler( state, { payload }) {
      return {
        ...state,
        userInfo: payload
      }
    },
  }
};
