import { getCarNo, setCarNo } from 'services/car_no';
import router from 'umi/router';
export default {
  namespace: 'car_no',
  state: {
  },
  effects: {
    *setCarNo({ payload, callback }, { call, put }) {
      const response = yield call(setCarNo, payload);

      callback(response);
    },
    *getCarNo({ payload, callback }, { call, put }) {
      const response = yield call(getCarNo, payload);

      callback(response)
    }
  },
  reducers: {
  }
};
