import proxyRequest from 'utils/request';
import { baseUrl } from '@/utils/baseServer';

export async function userInfo(params) {
  return proxyRequest.get(`${baseUrl}/portal/userInfo`, params);
}

export async function orderCount(params) {
  return proxyRequest.get(`${baseUrl}recycleapi/order/user/orderCount`, params);
}
