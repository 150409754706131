import React, { Component } from 'react'
import { connect } from 'dva';
import { Modal, Icon, InputItem, NavBar, Button } from 'antd-mobile';
import router from 'umi/router';
import styles from './index.less';
import fa from '../../assets/css/font-awesome.css';

@connect(({ personalInfo }) => ({ personalInfo }))
class PersonalInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: ''
    }
  }

  // componentDidMount() {
  //   const { dispatch } = this.props;
  //   dispatch({
  //     type: 'personalInfo/get',
  //     payload: {}
  //   })
  // }

  submit() {
    return;
    // let n = this.state.name;
    // if (n.length < 2) {
    //   return;
    // }
    // let p = this.state.phone;
    // if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(p))){  
    //   return; 
    // }
    // const { dispatch } = this.props;
    // dispatch({
    //   type: 'personalInfo/set',
    //   payload: {n, p},
    //   callback: (res) => {
    //     router.push('/my');
    //   }
    // })

  }

  render() {
    
    //const { personalInfo = {} } = this.props.personalInfo;
    //const { name = '', phone = '' } = personalInfo;

    // let showPhone = '';
    // if (phone.length == 11) {
    //   showPhone = phone.substring(0, 4) + "****" + phone.substring(8);
    // }

    return (<div>
      <NavBar
        mode="light"
        icon={<Icon type="left" />}
        onLeftClick={() => router.push('/my')}
      >留下个人信息</NavBar>
      <InputItem value={this.state.name} onChange={(v) => this.setState({name: v})} className={styles.car_no} placeholder="输入姓名" />
      <InputItem value={this.state.phone} onChange={(v) => this.setState({phone: v})} className={styles.car_no} placeholder="输入手机号" />
      <Button type="primary" className={styles.submit} onClick={this.submit.bind(this)}>提交</Button>
    </div>)
  }

}

export default PersonalInfo;