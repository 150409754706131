import React, { Component } from 'react'
import { Carousel } from 'antd-mobile';
import styles from './index.less'
import { connect } from 'dva';
import router from 'umi/router';
import imgURL from '../../assets/default-avatar.png';

class NameCard extends React.Component {

  render() {
    const avatar = this.props.avatar || imgURL;
    
    return (
      <div className={styles.name_content}>
        <div className={styles.content_card}>
          <img className={styles.avatar} src={avatar} alt="" />
          <div className={styles.content_name}>{this.props.name}</div>
          <div className={styles.wallet}>
            <div className={styles.hint}>钱包余额</div>
            <div className={styles.balance}>{this.props.balance || '0.00'}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default NameCard;
