import { getSupport } from 'services/support';
import router from 'umi/router';
export default {
  namespace: 'support',
  state: {
  },
  effects: {
    *getSupport({ payload, callback }, { call }) {
      const response = yield call(getSupport, payload);
      // if (!response) {
      //   return;
      // }
      callback(response);
    }
  },
  reducers: {
  }
};