import { userInfo } from 'services/my';
import router from 'umi/router';
export default {
  namespace: 'my',
  state: {
    userInfo: undefined
  },
  effects: {
    *userInfo({ payload, callback }, { call, put }) {
      const response = yield call(userInfo, payload);
      if (!response || !response.data) {
        return;
      }
      if (response.code == 200) {
        yield put({
          type: 'userInfoHandler',
          payload: response.data
        })
      }
    },
  },
  reducers: {
    userInfoHandler( state, { payload }) {
      return {
        ...state,
        userInfo: payload
      }
    },
  }
};
