import proxyRequest from 'utils/request';
import { baseUrl } from '@/utils/baseServer';

export async function getVin(params) {
  return proxyRequest.get(`${baseUrl}/portal/vin`, params);
}

export async function setVin(params) {
  return proxyRequest.post(`${baseUrl}/portal/vin`, params);
}
