import { set } from 'services/personalInfo';
import router from 'umi/router';
export default {
  namespace: 'personalInfo',
  state: {
  },
  effects: {
    *set({ payload, callback }, { call }) {
      const response = yield call(set, payload);
      console.log('response', response);
      callback(response);
    },
  },
  reducers: {
  }
};
