import React, { Component } from 'react';
import { connect } from 'dva';
import { Button, Radio, Flex, ActivityIndicator } from 'antd-mobile';
import router from 'umi/router';
import styles from './index.less';
import '../../global-vars';

const RadioItem = Radio.RadioItem;
//var wx = require('weixin-js-sdk');
var pileNo = "bbbbb";
var appId = '';
var timeStamp = '';
var nonceStr = '';
var pack = '';
var signType = '';
var paySign = '';
function onBridgeReady() {
  WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
         "appId":appId,
         "timeStamp":timeStamp,
         "nonceStr":nonceStr,
         "package":pack,
         "signType":signType,
         "paySign":paySign
      },
      function(res){
      if(res.err_msg == "get_brand_wcpay_request:ok" ){

      }
      //alert(res.err_msg + `${appId}` + 'BBB' + `${timeStamp}` + 'BBB' + `${nonceStr}` + '#' + pack + '#' + signType + '#' + paySign);
  });
}

@connect(({charge}) => ({charge}))
class Charge extends Component {

  state = {
    value: 0,
    display: 'none',
    display1: 'block',
    disablePay: false,
    isDisabled: true,
    isScanning: false,
    sharp: 0,
    peak: 0,
    flat: 0,
    trough: 0
  }

  pollTimer = null;
  check = 0;
  isCalling = 0;

  componentDidMount() {
    if (this.state.isScanning) {
       return;
    }
    this.setState({
      isScanning: true
    });
    pileNo = "87yh";
    this.checkPile();
    // this.setState({
    //   display: 'block'
    // });
  }

  // scanCode = () => {
  //   //this.showUI();
  //   if (this.state.isScanning) {
  //      return;
  //   }
  //   this.setState({
  //     isScanning: true
  //   });
  //   var that = this;
  //   wx.scanQRCode({
  //       needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
  //       scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
  //       success: function (res) {
  //           setTimeout(() => {
  //             pileNo = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
  //             that.checkPile();
  //           }, 2000);
  //       }
  //   });
  // }

  checkPile = () => {
    const { dispatch } = this.props;
    var pileStatus = 0; // 3 scan error&not exist, 1 not bind, 2 busy, 0 idle, 4 information not complete
    dispatch({
      type: 'charge/checkPile',
      payload: { pileNo },
      callback: (res) => {
        this.setState({
          isScanning: false
        });
        if (res.code == 200) {
          pileStatus = res.data.pileStatus;
          pileNo = res.data.pileNo;
          if (pileStatus == 1) {
            alert("此充电桩还未绑定商家！");
          } else if (pileStatus == 2) {
            global.vars.sn1 = pileNo;
            //alert("此充电桩正在使用中！");
            global.vars.isCharging = true;
            router.replace({
                pathname: '/monitor/',
                query: {
                    pileNo: pileNo
                },
            });
          } else if (pileStatus == 0) {
            this.setState({
              display: 'block',
              display1: 'none',
              sharp: res.data.sharp,
              peak: res.data.peak,
              flat: res.data.flat,
              trough: res.data.trough
            });
            global.vars.sn1 = pileNo;
            var outTradeNo = res.data.outTradeNo;
            if (outTradeNo && outTradeNo.length > 6) {
              this.check = 3;
              this.setState({
                isScanning: true
              });
              this.pollTimer = setInterval(
                () => this.pollForTradeStatus(outTradeNo),
                4000
              );
              this.pollForTradeStatus(outTradeNo);		    
            }
          } else if (pileStatus == 3) {
            alert("扫码错误！充电桩不存在！");
          } else if (pileStatus == 4) {
            alert("此充电桩商家信息不完善！");
          } else if (pileStatus == 5) {
            alert("系统维护中！");
          }
        } else {
          alert("请退出公众号，用微信扫描充电桩二维码开始充电");//+res.code);
        }
      }
    })
  }

  pay = () => {
    if (this.isCalling == 1) {
      return
    }
    this.isCalling = 1;
    const { dispatch } = this.props;
    var orderType = "00";
    if (this.state.value == 0) {
      orderType = "00";
    } else if (this.state.value == 1) {
      orderType = "01";
    } else if (this.state.value == 2) {
      orderType = "02";
    }
    //let scanUuid = "SCAN1";
    dispatch({
      type: 'charge/pay',
      payload: { orderType, pileNo },
      callback: (res) => {
        appId = res.data.appId;
        pack = res.data.packageValue;
        timeStamp = res.data.timeStamp;
        nonceStr = res.data.nonceStr;
        signType = res.data.signType;
        paySign = res.data.paySign;
        var outTradeNo = res.data.outTradeNo;
        if (typeof WeixinJSBridge === "undefined"){
          if( document.addEventListener ){
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          }else if (document.attachEvent){
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        }else{
         onBridgeReady();
        }
        this.check = 3;
        if (this.pollTimer) {
          clearInterval(this.pollTimer);
        }
        this.pollTimer = setInterval(
            () => this.pollForTradeStatus(outTradeNo),
            5000
        );
        if (res.code == 201) {
          // 已经绑定过
          // router.push('/');
        }
	this.setState({
          isScanning: true
        });
      }
    })
  }

  startCharge = () => {
    const { dispatch } = this.props;
    //pileNo = "32010300000356";
    this.setState({
      isScanning: true
    });
    dispatch({
      type: 'charge/startCharge',
      payload: { pileNo },
      callback: (res) => {
        this.setState({
          isScanning: false
        });
        if (res.code == 200) {
          global.vars.isCharging = true;
          //router.replace('/monitor/${pileNo}');
          router.replace({
              pathname: '/monitor/',
              query: {
                  pileNo: pileNo
              },
          });
        } else if (res.code == 400) {
          alert(res.message);
        } else if (res.code == 500) {
          //alert(res.message);
        } else {
          alert("网络错误404");
        }
      }
    })
  }

  componentWillUnmount() {
    if (this.pollTimer) {
      clearInterval(this.pollTimer);
    }
  }

  pollForTradeStatus = (outTradeNo) => {
  	const { dispatch } = this.props;
  	dispatch({
      type: 'charge/checkPay',
      payload: { outTradeNo },
      callback: (res) => {
        /*
          SUCCESS--支付成功
          REFUND--转入退款
          NOTPAY--未支付
          CLOSED--已关闭
          REVOKED--已撤销(刷卡支付)
          USERPAYING--用户支付中
          PAYERROR--支付失败(其他原因，如银行返回失败)
          ACCEPT--已接收，等待扣款
        */
        var tradeState = res.message;
        if (res.code == 200) {
          // 已经绑定过
          // router.push('/');
          
          switch(tradeState) {
            case 'SUCCESS':
              clearInterval(this.pollTimer);
              this.setState({
                disablePay: true,
                isDisabled: false,
                isScanning: false
              });
	      this.isCalling = 0;
              break;
            case 'REFUND':
              clearInterval(this.pollTimer);
              this.setState({
                isScanning: false
              });
              this.isCalling = 0;
	      break;
            case 'NOTPAY':
              // do nothing, keep polling
              if (this.check < 1) {

              } else if (this.check < 2) {
                clearInterval(this.pollTimer);
                this.setState({
                  isScanning: false
                });
		this.isCalling = 0;
              } else {
                this.check -= 1;
              }
              break;
            case 'CLOSED':
              clearInterval(this.pollTimer);
              this.setState({
                isScanning: false
              });
	      this.isCalling = 0;
              break;
            case 'REVOKED':
              // do nothing, keep polling to 'CLOSED'
              break;
            case 'USERPAYING':
              // do nothing, keep polling
              break;
            case 'PAYERROR':
              // do nothing, keep polling to 'CLOSED'
              break;
            case 'ACCEPT':
              // do nothing, keep polling
              break;
            default:
              break
          } 
        } else if (res.code == 410) { // no exist
           clearInterval(this.pollTimer);
           this.isCalling = 0;
           this.setState({
             isScanning: false
           });
        }
      }
    })
  }

  onChange1 = () => {  
    this.setState({
      value:0
    });
  };

  onChange2 = () => {  
    this.setState({
      value:1
    });
  };

  onChange3 = () => {  
    this.setState({
      value:2
    });
  };

  render() {
    return (
      <div>
        <div>
          <ActivityIndicator toast text="正在加载" animating={this.state.isScanning} />
        </div>
        <div className="charge-direct" style={{display: this.state.display1}}>
          请退出公众号，用微信扫描充电桩二维码开始充电。如果没有关注公众号，需要关注公众号。
        </div>
        <div className="chargePage" style={{display: this.state.display}}>
        <Flex direction="column">
          <Flex.Item>
            <div className="charging-car-gif"></div>
          </Flex.Item>
        </Flex>
        <Flex direction="row" style={{marginTop:'20px'}}>
           <Flex.Item style={{height:'50px'}}> 
              <RadioItem checked={this.state.value === 0} onChange={this.onChange1.bind(this)}><div style={{textAlign:'right',marginRight:'0px'}}>￥20</div></RadioItem>
            </Flex.Item>
            <Flex.Item style={{height:'50px'}}>
              <RadioItem checked={this.state.value === 1} onChange={this.onChange2.bind(this)}><div style={{textAlign:'right',marginRight:'0px'}}>￥50</div></RadioItem>
            </Flex.Item>
            <Flex.Item style={{height:'50px'}}>
              <RadioItem checked={this.state.value === 2} onChange={this.onChange3.bind(this)}><div style={{textAlign:'right',marginRight:'0px'}}>￥100</div></RadioItem>
            </Flex.Item>  
        </Flex>
        <Flex direction="column">
          <Flex.Item style={{width:'100%'}}>
            <Flex direction="row">
              <Flex.Item>
                <Flex direction="column">
                  <Flex.Item>
                    <Button className="stop-btn1" disabled={this.state.disablePay} onClick={this.pay.bind(this)}>
                      支付
                    </Button>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
              <Flex.Item> 
                <Flex direction="column">
                  <Flex.Item> 
                    <Button className="stop-btn1" disabled={this.state.isDisabled} onClick={this.startCharge.bind(this)}>
                      开始充电
                    </Button>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
            </Flex>
          </Flex.Item>
          <Flex.Item>
            <div className="charge-hint">请先将充电枪插在汽车上，然后点击开始充电。剩余金额在充电结束后将退回您的账户。如意外中止充电，系统也会在大约1小时左右退回预付款。</div>
          </Flex.Item>
          <Flex.Item className="addf1">
            <Flex direction="row">
              <Flex.Item className="addf-fee">
                尖电价：{this.state.sharp}元/度
              </Flex.Item>
              <Flex.Item className="addf-fee">
                峰电价：{this.state.peak}元/度
              </Flex.Item>
            </Flex>
          </Flex.Item>
          <Flex.Item className="addf">
            <Flex direction="row">
              <Flex.Item className="addf-fee">
                平电价：{this.state.flat}元/度
              </Flex.Item>
              <Flex.Item className="addf-fee">
                谷电价：{this.state.trough}元/度
              </Flex.Item>
            </Flex>
          </Flex.Item>
        </Flex>
        </div>
      </div>
    );
  }
}

export default Charge;
