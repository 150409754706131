import React, { Component } from 'react'
import { connect } from 'dva';
import { Modal, List, Icon } from 'antd-mobile';
import NameCard from '../../components/name-card';
import router from 'umi/router';
import styles from './index.less';
import fa from '../../assets/less/font-awesome.less';
import '../../global-vars';

@connect(({ my }) => ({ my }))
class MyIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nowdata: 0,
      name: '',
      avatar:'',
    }
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({
      type: 'my/userInfo',
      payload: {}
    })
  }

  render() {
    const { my } = this.props;
    const { userInfo: { nickname, headImgUrl } = {} } = my;
    // 'Toad''http://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eq8oTj4Z5YfuM76JZGSXbI4kAppJaU7aicY7baxugYQImoGWuZSsxLiaRlibw3gRH8PAon0BI3nWn1qQ/132'
    return (
      <div className={styles.content_me}>
        <NameCard
          name={nickname}
          avatar={headImgUrl}
        />

        <div className={styles.service_info}>
          <List>
            <List.Item className={styles['list-item']} arrow="horizontal" onClick={() => router.push('/history')}>
              <span className={styles['item-text']}><i className={`${fa.fa} ${fa['fa-navicon']}`}></i>充电记录</span>
            </List.Item>
            {/*
            <List.Item className={styles['list-item']} arrow="horizontal" onClick={() => router.push('/collection')}>
              <span className={styles['item-text']}><i className={`${fa.fa} ${fa['fa-star-o']}`}></i>我的收藏</span>
            </List.Item>
	    */}
            <List.Item className={styles['list-item']} arrow="horizontal" onClick={() => router.push('/personalInfo')}>
              <span className={styles['item-text']}><i className={`${fa.fa} ${fa['fa-address-card-o']}`}></i>完善个人信息</span>
            </List.Item>
            <List.Item className={styles['list-item']} arrow="horizontal" onClick={() => router.push('/support')}>
              <span className={styles['item-text']}><i className={`${fa.fa} ${fa['fa-headphones']}`}></i>联系我们</span>
            </List.Item>
          </List>
          {global.vars.isCharging?(
            <div className={styles.charging} onClick={() => router.replace('/monitor')}>您正在充电中，点击查看状态</div>
            ):null
          }
        </div>
      </div>
    )
  }
}
export default MyIndex;
