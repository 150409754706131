import { createOrder, checkPile, startCharge, checkPay } from '../../services/charge';
import router from 'umi/router';
export default {
  namespace: 'charge',
  state: {
  },
  effects: {
    // *scan({ payload, callback }, { call, put }) {
    //   const response = yield call(scan, payload);

    //   if (response.code == 200) {
    //     yield put({
    //       type: 'scanHandler',
    //       payload: response.data
    //     })
    //   }
    // },
    *pay({ payload, callback }, { call }) {
      const response = yield call(createOrder, payload);
      console.log('response', response)
      callback(response)
    },
    *startCharge({ payload, callback }, { call }) {
      const response = yield call(startCharge, payload);
      console.log('response', response)
      callback(response)
    },
    *checkPay({ payload, callback }, { call }) {
      const response = yield call(checkPay, payload);
      console.log('response', response)
      callback(response)
    },
    *checkPile({ payload, callback }, { call }) {
      const response = yield call(checkPile, payload);
      console.log('response', response)
      callback(response)
    }
  },
  reducers: {
    // scanHandler( state, { payload }) {
    //   return {
    //     ...state,
    //     userInfo: payload
    //   }
    // },
  }
};
