import { orderData } from 'services/history';
import router from 'umi/router';
export default {
  namespace: 'history',
  state: {
  },
  effects: {
    *orderData({ payload, callback }, { call }) {
      const response = yield call(orderData, payload);
      // if (!response) {
      //   return;
      // }
      callback(response);
    }
  },
  reducers: {
  }
};
