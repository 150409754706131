import React, { Component } from 'react';
import { connect } from 'dva';
//import { Button, SearchBar, Modal, Flex, InputItem, WingBlank, WhiteSpace } from 'antd-mobile';
//import Link from 'umi/link';
import router from 'umi/router';
//import styles from './style.less'
//import { Random } from 'mockjs';
import '../../global-vars';

// var wx = require('weixin-js-sdk');
// wx.ready(function () {
//     alert("ready----");
//     router.replace('/charge');
// });

// wx.error(function(res){
//   alert("error-----");
// });

@connect(({bind}) => ({bind}))
class Bind extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    let { query: { code, state } } = this.props.location;
    const { dispatch } = this.props;
    if (code == undefined) {
      alert("登录错误!");
      return;
    }
    if (state == undefined) {
      state = '';
    }
    dispatch({
      type: 'bind/toBind',
      payload: { code, state },
      callback: (res) => {
        if (res.code == 201) {
          global.vars.openId = res.message;
          router.replace('/charge');
          //this.registerWx();
        }
      }
    })
  }
  // registerWx() {
  //   const { dispatch } = this.props;
  //   alert(location.href.split('#')[0]);
  //   dispatch({
  //       type: 'bind/js',
  //       payload: { url: location.href.split('#')[0] },
  //       callback: (res) => {
  //   //alert(location.href.split('#')[0].length);
  //   //alert(res.data.appId + '!!' + res.data.timestamp + '!!' + res.data.nonceStr  + '!!' + res.data.signature);
  //           wx.config({
  //               debug: true,
  //               appId: res.data.appId,
  //               timestamp: res.data.timestamp,
  //               nonceStr: res.data.nonceStr,
  //               signature: res.data.signature,
  //               jsApiList: [
  //                 'scanQRCode'
  //               ]
  //           });
  //           if (res.code == 201) {
  //           }
  //       }
  //   })
  // }
  // checkSendable(v) {
  //   const phone = v.trim();
  //   this.setState({
  //     phone: phone,
  //     sendable: /^1\d{10}$/.test(phone)
  //   });
  // }
  // codeChanged(v) {
  //   this.setState({
  //     code: v
  //   });
  // }
  // toSendCode() {
  //   this.setState({
  //     vcodeModal: true,
  //     vcodeUrl: "/server/portal/vcode?" + Math.floor(Math.random() * 900000 + 100000)
  //   })
  // }

  // sendCode() {
  //   // request send code
  //   const { phone, vcode } = this.state;
  //   const {dispatch} = this.props;
  //   dispatch({
  //     type: 'bind/sendCode',
  //     payload: { phone, vcode },
  //     callback: (res) => {
  //       if (res.code == 200) {
  //         this.setState({
  //           vcodeModal: false,
  //           countdown: 60
  //         })
  //         let timer = setInterval(() => {
  //           if (this.state.countdown > 0) {
  //             this.setState({
  //               countdown: this.state.countdown - 1
  //             })
  //           } else {
  //             clearInterval(timer);
  //           }
  //         }, 1000)
  //       } else {
  //         this.setState({
  //           vcodeUrl: "/server/portal/vcode?" + Math.floor(Math.random() * 900000 + 100000),
  //           vcode: ''
  //         })
  //       }
  //     }
  //   })
  // }
  // vcodeDone() {
  //   if (/^[0-9A-Za-z]{4}$/.test(this.state.vcode)) {
  //     this.sendCode()
  //   }
  // }
  // bindPhone() {
  //   const { dispatch } = this.props;
  //   const { phone, code } = this.state;
  //   if (!/1\d{10}/.test(phone)) {
  //     return;
  //   }
  //   if (!code) {
  //     return;
  //   }
  //   dispatch({
  //     type: 'bind/bindPhone',
  //     payload: { phone, code }
  //   })
  // }

  // scan() {
  //   router.push('/scan');
  // }

  render() {
    return (
      <div>
      </div>
    )
  }

}

export default Bind;