import proxyRequest from 'utils/request';
import { baseUrl } from '@/utils/baseServer';

// export async function scan(params) {
//   return proxyRequest.get(`${baseUrl}/portal/scan`, params);
// }

export async function createOrder(params) {
  return proxyRequest.post(baseUrl + '/charge/createOrder', params);
}

export async function checkPile(params) {
  return proxyRequest.get(baseUrl + '/charge/checkPile', params);
}

export async function checkPay(params) {
  return proxyRequest.get(baseUrl + '/charge/checkPay', params);
}

export async function startCharge(params) {
  return proxyRequest.get(baseUrl + '/charge/testCharge', params);
}
