import React, { Component } from 'react'
import { connect } from 'dva';
import { Modal, List, Button, Icon, NavBar } from 'antd-mobile';
import router from 'umi/router';
import styles from './index.less';
import fa from '../../assets/css/font-awesome.css';
import '../../global-vars';

const Item = List.Item;
const Brief = Item.Brief;
@connect(({ history }) => ({ history }))
class History extends Component {	

  state = {
    pageNo: 1,
    prevDisable: true,
    nextDisable: false,
    orderData: []
  }	
  // orderData = [{
  // 	money: "57.34",
  // 	time: "2021-11-22",
  // 	orderNo: "0020200812000005"
  // },{
  // 	money: "66",
  // 	time: "2021-01-03",
  // 	orderNo: "0020200812000065"
  // },{
  // 	money: "23.4",
  // 	time: "2019-07-13",
  // 	orderNo: "0020200812000007"
  // }];

  componentDidMount() {
    const { dispatch } = this.props;
    //var openId = global.vars.openId;
    var pageNo = this.state.pageNo;
    var pageSize = 6;
    var that = this;
    dispatch({
      type: 'history/orderData',
      payload: { pageNo, pageSize },
      callback: (res) => {
      	/*{"code":200,"message":null,"data":
      	[{"changeAmount":"34.12","orderNo":"0020200811000001","placeTime":"2020-08-11"},
      	{"changeAmount":"66.70","orderNo":"0020200811000002","placeTime":"2020-08-11"},
      	{"changeAmount":"78.00","orderNo":"0020200811000003","placeTime":"2020-08-11"}]}
      	*/
	res.data.forEach(function(e) {
            e.placeTime1 = e.placeTime.split(" ")[0]
            if (!e.timeStr || e.timeStr.length < 1) {
              e.timeStr = ""
            } else {
              e.timeStr = that.parseTime(e.timeStr)
            }
        })
        this.setState({
  	      orderData: res.data
  	    });
        if (res.data.length < 1) {
          this.setState({
            nextDisable: true
          });
        }

      }
    })
  }

  parseTime(str) {
    var aTime = parseInt(str)
    var aHour = parseInt(aTime/60)
    var aMin = aTime%60
    var aTimeStr = aHour+"小时"+aMin+"分"
    return aTimeStr
  }

  nextData = () => {
    const { dispatch } = this.props;
    //var openId = global.vars.openId;
    var pageNo = this.state.pageNo + 1;
    this.setState({
      pageNo: pageNo
    });
    var pageSize = 6;
    var that = this;
    dispatch({
      type: 'history/orderData',
      payload: { pageNo, pageSize },
      callback: (res) => {
	res.data.forEach(function(e) {
            e.placeTime1 = e.placeTime.split(" ")[0]
            if (!e.timeStr || e.timeStr.length < 1) {
              e.timeStr = ""
            } else {
              e.timeStr = that.parseTime(e.timeStr)
            }
        })
        this.setState({
          prevDisable: false,
          orderData: res.data
        });
        if (res.data.length < 1) {
          this.setState({
            nextDisable: true
          });
        }

      }
    })
  }

  prevData = () => {
    const { dispatch } = this.props;
    //var openId = global.vars.openId;
    var pageNo = this.state.pageNo - 1;
    this.setState({
      pageNo: pageNo
    });
    if (pageNo == 1) {
      this.setState({
        prevDisable: true
      });
    }
    var pageSize = 6;
    var that = this;	  
    dispatch({
      type: 'history/orderData',
      payload: { pageNo, pageSize },
      callback: (res) => {
	res.data.forEach(function(e) {
            e.placeTime1 = e.placeTime.split(" ")[0]
            if (!e.timeStr || e.timeStr.length < 1) {
              e.timeStr = ""
            } else {
              e.timeStr = that.parseTime(e.timeStr)
            }
        })
        this.setState({
          nextDisable: false,
          orderData: res.data
        });
      }
    })
  }
  showOrderDetail = (orderNo) => {
    this.state.orderData.forEach(function(e) {
      if (e.orderNo == orderNo) {
        alert('订单号：' + e.orderNo +'\r\n' + '充电时长：' + e.timeStr +'\r\n'
         + '充电：' + e.degreeStr + "kw" +'\r\n' + '充电金额：' + e.changeAmount + '元' +'\r\n'
         + '预付款：' + e.preAmount + '元' +'\r\n' + '退款：' + e.price + '元' +'\r\n'
         + '订单创建时间：' + e.placeTime + '\r\n'+ '充电结束时间：' + e.updateTime + '\r\n'
         + '桩编号：' + e.sn + '\r\n');
        return;
      }
    })
  }

  render() {
    let pageBtns;
    if (this.state.prevDisable == true) {
      pageBtns = (
        <div style={{textAlign: 'center', marginTop: '10vw'}}>
          <Button type="primary" inline disabled onClick={this.prevData.bind(this)}>上一页</Button>
          <Button type="primary" inline onClick={this.nextData.bind(this)}>下一页</Button>
        </div>)
    } else if (this.state.nextDisable == true) {
      pageBtns = (
        <div style={{textAlign: 'center', marginTop: '10vw'}}>
          <Button type="primary" inline onClick={this.prevData.bind(this)}>上一页</Button>
          <Button type="primary" inline disabled onClick={this.nextData.bind(this)}>下一页</Button>
        </div>)
    } else {
      pageBtns = (
      <div style={{textAlign: 'center', marginTop: '10vw'}}>
        <Button type="primary" inline onClick={this.prevData.bind(this)}>上一页</Button>
        <Button type="primary" inline onClick={this.nextData.bind(this)}>下一页</Button>
      </div>)
    }
    return (<div>
      <NavBar
        mode="light"
        icon={<Icon type="left" />}
        onLeftClick={() => router.replace('/my')}
      >充电记录</NavBar>
      <List renderHeader={() => ''} className="my-list">
        {
          this.state.orderData.map((item, index) => {
            if(item.changeAmount == null) {
              return
            } else {
              return <Item onClick={ () => this.showOrderDetail(item.orderNo)} multipleLine key={index} extra={item.placeTime1}>
	            充电{item.degreeStr}kw 消费{item.changeAmount}元 <Brief>订单号:{item.orderNo}</Brief>
	            </Item>
            }
          })
        }
      </List>
      
      { pageBtns }
      
    </div>)
  }

}

export default History;
